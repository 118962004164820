<template>
  <div>
    <div class="about-us">
      <div class="round-right"></div>
      <div class="round-left"></div>
      <div class="container">
        <div class="row about-us-wrapper">
          <div class="col">
            <div class="about-us-title">
              TROUBLESHOOTING
            </div>
            <div class="about-us-subtitle">
              Technology
            </div>

            <div class="workers-img-mobile" :style="{ 'background-image': `url('./css/images/workers_banner.png')` }"></div>

            <div class="about-us-desc">
              Создаем удобные сайты, сервисы, мобильные приложения, порталы, личные кабинеты, программы лояльности.
            </div>
            <div class="about-us-contacts">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
              <a class="contact-address">
                Республика Казахстан, г. Алматы, пр-т Абая 68/74
              </a>
            </div>
          </div>
          <div class="col">
            <div class="workers-img" :style="{ 'background-image': `url('./css/images/workers_banner.png')` }"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="services">
      <div class="container">
        <div class="services-title">
          Наши <span>услуги</span>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_1.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Поддержка
          </div>
          <div class="services-item-desc">
            <div>
              “На всех стадиях жизни проекта”
            </div>
            <div>
              Поддерживаем и развиваем ваши проекты, исправляем баги, следим за работоспособностью, безопасностью, обрабатываем обращения пользователей
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_2.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Разработка
          </div>
          <div class="services-item-desc">
            <div>
              “От дизайна до вывода в прод”
            </div>
            <div>
              Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_3.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Аутсорс&Аутстафф
          </div>
          <div class="services-item-desc">
            <div>
              “Усиление вашей команды”
            </div>
            <div>
              Можем взять проект под реализацию или определенный объем задач; выделить команду или определенных специалистов
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_4.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Интеграции
          </div>
          <div class="services-item-desc">
            <div>
              “Настроим коннект”
            </div>
            <div>
              Проведем интеграции с частными и с гос. сервисами. Также можем написать интеграционный сервис для вашего проекта
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_5.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Легаси проекты
          </div>
          <div class="services-item-desc">
            <div>
              “Он еще жив!”
            </div>
            <div>
              Поддерживаем и развиваем легаси проекты, создаем новые модули, фиксим баги, добавляем фичи
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_6.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Колл-центр
          </div>
          <div class="services-item-desc">
            <div>
              “От звонка до звонка”
            </div>
            <div>
              Наш колл-центр для решения ваших задач. Поддержка или обзвон клиентов и пользователей, на казахском и русском языках, по вашим скриптам, с ежедневными отчетами
            </div>
          </div>
        </div>
        <div class="services-item">
          <div class="services-item-img">
            <div class="service-img" :style="{ 'background-image': `url('./css/images/img_service_7.svg')` }"></div>
          </div>
          <div class="services-item-title">
            Серверная архитектура
          </div>
          <div class="services-item-desc">
            <div>
              “Упадет? Не должно”
            </div>
            <div>
              Разворачиваем физические или виртуальные машины, формируем требования к мощностям, настраиваем конфигурации, резервное копирование, широту канала, протоколы взаимодействия, проводим нагрузочное тестирование
            </div>
          </div>
        </div>

        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Поддержка</span>
              <span class="services-item-mobile-subtitle">“На всех стадиях жизни проекта”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Поддерживаем и развиваем ваши проекты, исправляем баги, следим за работоспособностью, безопасностью, обрабатываем обращения пользователей
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Разработка</span>
              <span class="services-item-mobile-subtitle">“От дизайна до вывода в прод”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Аутсорс&Аутстафф</span>
              <span class="services-item-mobile-subtitle">“Усиление вашей команды”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Поддерживаем и развиваем легаси проекты, создаем новые модули, фиксим баги, добавляем фичи
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Интеграции</span>
              <span class="services-item-mobile-subtitle">“Настроим коннект”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Проведем интеграции с частными и с гос. сервисами. Также можем написать интеграционный сервис для вашего проекта
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Легаси проекты</span>
              <span class="services-item-mobile-subtitle">“Он еще жив!”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Поддерживаем и развиваем легаси проекты, создаем новые модули, фиксим баги, добавляем фичи
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Колл-центр</span>
              <span class="services-item-mobile-subtitle">“От звонка до звонка”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Наш колл-центр для решения ваших задач. Поддержка или обзвон клиентов и пользователей, на казахском и русском языках, по вашим скриптам, с ежедневными отчетами
          </span>
        </div>
        <div class="services-mobile-border"></div>
        <div class="services-item-mobile">
          <div class="d-flex justify-content-between align-items-center">
            <div class="services-item-mobile-img">

            </div>
            <div class="services-item-mobile-desc">
              <span class="services-item-mobile-title">Серверная архитектура</span>
              <span class="services-item-mobile-subtitle">“Упадет? Не должно”</span>
            </div>
          </div>
          <span class="services-item-mobile-text">
            Разворачиваем физические или виртуальные машины, формируем требования к мощностям, настраиваем конфигурации, резервное копирование, широту канала, протоколы взаимодействия, проводим нагрузочное тестирование
          </span>
        </div>
        <div class="services-mobile-border"></div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="skills-block">
          <div class="skills-left">
            <div class="section-title">
              Мы <span>умеем в:</span>
            </div>
            <div class="skills-focus-img">

            </div>
          </div>
          <div class="skills-right">
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/uiux.svg')` }">

              </div>
              <div class="skills-item-title">
                UI/UX
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_mobile.svg')` }">

              </div>
              <div class="skills-item-title">
                Mobile
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_ecommerce.svg')` }">

              </div>
              <div class="skills-item-title">
                E-commerce
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_marketplaces.svg')` }">

              </div>
              <div class="skills-item-title">
                Marketplaces
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_fintech.svg')` }">

              </div>
              <div class="skills-item-title">
                Fin-tech
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_edtech.svg')` }">

              </div>
              <div class="skills-item-title">
                Ed-tech
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_healthtech.svg')` }">

              </div>
              <div class="skills-item-title">
                Health-tech
              </div>
            </div>
            <div class="skills-item">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_traveltech.svg')` }">

              </div>
              <div class="skills-item-title">
                Travel-tech
              </div>
            </div>
            <div class="skills-item-big">
              <div class="skills-item-img" :style="{ 'background-image': `url('./css/images/img_business.svg')` }">

              </div>
              <div class="skills-item-title">
                Автоматизация бизнеса
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title">
          Наши <span>кейсы</span>
        </div>
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="cases-tabs box-shadow">
              <div class="cases-tab"
                   @click="currentTab = 'all'"
                   :class="{ 'cases-tab-active': currentTab === 'all' }"
              >
                Все
              </div>
              <div class="cases-tab"
                   @click="currentTab = 'sites'"
                   :class="{ 'cases-tab-active': currentTab === 'sites' }"
              >
                Сайты
              </div>
              <div class="cases-tab"
                   @click="currentTab = 'mobiles'"
                   :class="{ 'cases-tab-active': currentTab === 'mobiles' }"
              >
                Мобильные приложения
              </div>
              <div class="cases-tab"
                   @click="currentTab = 'portals'"
                   :class="{ 'cases-tab-active': currentTab === 'portals' }"
              >
                Порталы
              </div>
            </div>
            <router-link to="/portfolio" class="btn-see-all">Посмотреть все</router-link>
          </div>
          <div class="tabs-content">
            <transition :name="transitionName">
              <div v-if="currentTab === 'all'">
                <div class="portfolio-block">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
                  <div class="portfolio-item-min box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
                  <div class="portfolio-item-min box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
                </div>

                <div class="portfolio-block-mobile">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
                </div>
              </div>
            </transition>
            <transition :name="transitionName">
              <div v-if="currentTab === 'sites'">
                <div class="portfolio-block">
                  <div class="portfolio-item-min box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-min.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
                  <div class="portfolio-item-min box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-min.png')` }"></div>
                </div>

                <div class="portfolio-block-mobile">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/aerc-site-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/dashboard-site-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/site/elbasy-site-big.png')` }"></div>
                </div>
              </div>
            </transition>
            <transition :name="transitionName">
              <div v-if="currentTab === 'mobiles'">
                <div class="portfolio-block">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
                </div>

                <div class="portfolio-block-mobile">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/birge-app-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/app/gastreat-app-big.png')` }"></div>
                </div>
              </div>
            </transition>
            <transition :name="transitionName">
              <div v-if="currentTab === 'portals'">
                <div class="portfolio-block">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
                </div>

                <div class="portfolio-block-mobile">
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/eri-portal-big.png')` }"></div>
                  <div class="portfolio-item-big box-shadow"
                       :style="{ 'background-image': `url('./css/images/portfolio/portal/qamqor-portal-big.png')` }"></div>
                </div>
              </div>
            </transition>
            <router-link to="/portfolio" class="btn-see-all-mobile">Посмотреть все</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title w-100 text-center">
          Наши <span>клиенты</span>
        </div>
        <div class="clients-block">
          <div class="client-item" v-for="(client, index) in clients" :key="index">
            <div class="client-item-back" :style="{ 'background-image': `url('./css/images/clients/${client.logo}')` }">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title w-100 text-center">
          Наши <span>продукты</span>
        </div>
        <div class="products-block">
          <div class="product-left">
            <div class="product-name">
              DJOON
            </div>
            <div class="product-left-back">

            </div>
            <div class="product-desc">
              Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
            </div>
            <a class="btn-go" href="https://djoon.kz/" target="_blank">
              Перейти на сайт
            </a>
          </div>
          <div class="product-right">
            <div class="product-name">
              RentMate
            </div>
            <div class="product-right-back">

            </div>
            <div class="product-desc">
              Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
            </div>
            <a class="btn-go" href="https://portal.rentmate.kz/" target="_blank">
              Перейти на сайт
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title w-100 text-center">
          Наши <span>партнеры</span>
        </div>
        <div class="partners">
          <div class="partner-item" v-for="(partner, index) in partners" :key="index">
            <div class="partner-logo" :style="{ 'background-image': `url('./css/images/partners/${partner.logo}')` }">

            </div>
            <div class="partner-desc">
              {{partner.desc}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="d-flex justify-content-between contacts-main-wrapper">
          <div class="contacts">
            <div class="contacts-title">
              Связаться <span>с нами</span>
            </div>
            <div class="contacts-desc">
              Свяжитесь с нами удобным для вас способом, или заполните форму и мы напишем вам на почту.
            </div>
            <div class="contacts-item">
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
            </div>
            <div class="contacts-item">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
            </div>

            <div class="requisites">
              <div class="requisites-title">
                Реквизиты
              </div>
              <div class="requisites-text">
                ТОО "TROUBLESHOOTING TECHNOLOGY"
              </div>
              <div class="requisites-text">
                БИН<span>191140015704</span>
              </div>
              <div class="requisites-text">
                ИИК<span>KZ51998BTB0000517245</span>
              </div>
              <div class="requisites-text">
                АО «First Heartland Jýsan Bank»
              </div>
              <div class="requisites-text">
                БИН<span>TSESKZKA</span>
              </div>
            </div>
          </div>

          <div class="email-form">
            <div class="email-form-logo"></div>
            <div class="email-form-inputs">
              <el-input placeholder="Имя" v-model="name"></el-input>
              <el-input placeholder="E-mail" v-model="email"></el-input>
            </div>
            <el-input
                class="email-form-textarea"
                type="textarea"
                :rows="10"
                placeholder="Текст сообщения"
                v-model="message">
            </el-input>
            <button class="email-form-btn">
              Отправить
            </button>
          </div>

        </div>

        <div class="map-block">
          <div class="contacts-map-title">
              Республика Казахстан, г. Алматы, пр-т Абая 68/74
          </div>
          <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.5964320532607!2d76.90289601496097!3d43.23892028713921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883693aeb7dfe15%3A0x19e7c91d09661b35!2z0L_RgC3Rgi4g0JDQsdCw0Y8gNjgvNzQsINCQ0LvQvNCw0YLRiyA1MDA1MDA!5e0!3m2!1sru!2skz!4v1643264170689!5m2!1sru!2skz" allowfullscreen="allowfullscreen" loading="lazy" style="border: 0px; border-radius: 0 0 15px 15px; width: 100%; height: 100%;"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'MainPage',
  data() {
    return {
      transitionName: 'fade',
      currentTab: 'all',
      clients: [
        {logo: 'clients-1.svg'},
        {logo: 'clients-2.svg'},
        {logo: 'clients-3.svg'},
        {logo: 'clients-4.svg'},
        {logo: 'clients-5.svg'},
        {logo: 'clients-6.svg'},
        {logo: 'clients-7.svg'},
        {logo: 'clients-8.svg'},
        {logo: 'clients-9.svg'},
        {logo: 'clients-10.svg'},
        {logo: 'clients-11.svg'},
        {logo: 'clients-12.svg'},
        {logo: 'clients-13.svg'},
        {logo: 'clients-14.svg'},
        {logo: 'clients-15.svg'},
        {logo: 'clients-16.svg'},
        {logo: 'clients-17.svg'},
        {logo: 'clients-18.svg'},
        {logo: 'clients-19.svg'},
        {logo: 'clients-20.svg'},
      ],
      partners: [
        {logo: 'djoon.svg', desc: 'Организация разработавшая платформу для дистанционного обучения тысячи сотрудников по всему миру. Улучшают сервис и повышают продажи.'},
        {logo: 'dk.svg', desc: 'АЦК привлекается к проведению экспертизы действующего законодательства и проектов нормативных правовых актов Республики Казахстан, подготовке и представлению в государственные органы заключений и предложений.'},
        {logo: 'kazteport.svg', desc: 'SАО «Казтелепорт» — дочерняя организация АО «Народный Банк Казахстана» крупный инфокоммуникационный и облачный провайдер Казахстана.'},
        {logo: 'arda.png', desc: 'Ассоциация развития Digital Агенств, профессионалов рынка Digital. ARDA является объединением юридических лиц, оказывающих цифровые услуги, такие как: разработка и размещение рекламы, создание web-сайтов, создание маркетинговых решений. '},
      ],
      name: '',
      email: '',
      message: '',
    }
  },
}
</script>
<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>
